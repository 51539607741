@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.input {

    :global {
        .form-group {
            margin-bottom: 20px;
        }
        .error-wrapper {
            background-color: $color-light-pink;
            color: white;
            font-size:10px;
            padding: 2px 5px;
            letter-spacing: 0.1em;
            font-family: $font-primary;
        }
        .form-control {
            font-size: 1.4rem;
            border: 1px solid #f2f2f2;
            border-radius: 0;
            padding: 13.5px 15px;
            transition: all .3s;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &.hasValue {
                border-color: #ED8987;
                color: #dd817f;
            }
        }
    }

    label {
        font-size: 1.4rem;
        margin-bottom: 10px;
    }

    .countWrapp {
        float: right;
        color: #8f8f8f;
        font-size: 1.2rem;
    }

    .inputWrapp {
        position: relative;

        .error-wrapper {
            background-color: $color-pink;
            font-family: $font-primary;
            color: black;
            font-size:10px;
            letter-spacing: 0.1em;
        }
        
        .invalidFeedback {
            position: absolute;
            top: 50%;
            color: #dc5955;
            transform: translateY(-50%) translateX(0);
            left: 1px;
            opacity: 1;
            padding: 12px 15px;
            width: calc(100% - 2px);
            background-color: #ffffff;
            transition: all 0.4s;
            overflow: hidden;
        }

        &:hover {
            .invalidFeedback {
                opacity: 0;
                width: 0;
                font-size: 0.5rem;
            }
        }

        input {
            &:focus, &:hover {
                + .invalidFeedback {
                    opacity: 0;
                    width: 0;
                    font-size: 0.5rem;
                }
            }
        }
    }

    .is-invalid {
        input.form-control {
            border-color: #f8dedd;
        }
    }
}

:global {
    .input {
        &.edit {
            label {
                font-size: 1.6rem;
                font-weight: 500;
            }
        }
        &.wedding {
            label {
                margin: 0;
            }

            input {
                padding: 13px 0;
                border: none;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        &.turquise {
            .form-control {
                border: 1px solid #e9e9e9;
                background: transparent;

                &:focus {
                    background-color: #ffffff;
                    border-color: #bae9eb;
                }

                &.hasValue {
                    border-color: #bae9eb;
                    color: #19b5bc;
                }
            }
        }

        &.noBorder {
            input {
                border: none;
                padding: 14px 0;
                font-size: 1.8rem;

                &::-webkit-input-placeholder{
                    color: #aaaaaa;
                    font-weight: 600;
                }

                &::-moz-placeholder{
                    color: #aaaaaa;
                    font-weight: 600;
                }

                &:-ms-input-placeholder {
                    color: #aaaaaa;
                    font-weight: 600;
                }

                &:-moz-placeholder {
                    color: #aaaaaa;
                    font-weight: 600;
                }
            }
        }
    }

    .selectBorderRed {
        input {
            border: 2px solid #fbf2f2 !important;
            height: 50px;
        }
    }

}

:global {
    .BudgetWide {
        width: 194px;
        text-align: left;

        @media(max-width:600px) {
            width: 150px;
        }
    }
    .BudgetCurrency {
        text-align: right;
        padding-right: 5px;

        @media(max-width:600px) {
            width: 150px;
            //padding: 5px !important;
        
        }
    }
    input.BudgetCurrency::-webkit-outer-spin-button,
    input.BudgetCurrency::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; 
    }

    .inputOrganizer {
        .form-control {
            border:2px solid #fbf2f2;
        }

        label {
            font-size: 1.2rem;
            font-weight: bold;
            color: #edbdbc;
            letter-spacing: 1px;
            margin-bottom: 5px;
            text-transform: uppercase;
        }
    }

    .BudgetCurrency .form-control {
        padding: 0 !important;
    }
}

.inputCurrency {
    position: relative;
    display: inline-block;

    .currency {
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        padding: 0 5px;
    }
}

.buttonsIncDec {
    position: absolute;
    top: 50%;
    right: 5px;
    display: flex;
    transform: translateY(-50%);

    div {
        width: 20px;
        height: 20px;
        margin: 2px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        font-size: 1.8rem;
        line-height: 1em;
        text-align: center;
        cursor: pointer;
    }
}