.section {
    .h1 {
        position: relative;
        color: $color-grey;
        font-family: $font-secondary;
        font-size: 3rem;
        display: inline-block;
        padding-bottom: 15px;
        margin-bottom: 40px;

        @media (max-width: 991px) {
            font-size: 2.5rem;
        }

        &:before, &:after {
            content: '';
            display: block;
            height: 1px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        &:before {
            width: 70px;
            background-color: $color-bg-pink;
            bottom: 4px;
        }

        &:after {
            width: 50px;
            background-color: #e9e9e9;
            bottom: 0;
        }


        .icoRight {
            padding-left: 20px;
            margin-bottom: 3px;

            svg {
                width: 14px;
                height: 14px;

                polygon {
                    fill: $color-grey;
                }
            }
        }
    }

    &.sectionPaddingTop {
        padding-top: 85px;

        @media (max-width: 991px) {
            padding-top: 55px;
        }

        @media (max-width: 767px) {
            padding-top: 45px;
        }
    }

    &.sectionPaddingBottom {
        padding-bottom: 85px;

        @media (max-width: 991px) {
            padding-bottom: 55px;
        }

        @media (max-width: 767px) {
            padding-bottom: 45px;
        }
    }

    &.sectionGray {
        background-color: #fcfcfc;
    }
}

.pointner {
    cursor: pointer;
}