.owlHomeTop {

    .owl-nav {
        margin: 0;
    }

    .owl-prev, .owl-next {
        width: 16px;
        height: 29px;
        background-size: contain;
        position: absolute;
        top: 50%;
        transform: translatey(-50%);
        outline: none;
    }

    .owl-next {
        background: url("/img/left-arrow.png") no-repeat !important;
        right: -65px;

        @media (max-width: 1330px) {
            right: -35px;
        }

        @media (max-width: 575px) {
            right: 0;
        }
    }

    .owl-prev {
        background: url("/img/right-arrow.png") no-repeat !important;
        left: -65px;

        @media (max-width: 1330px) {
            left: -35px;
        }

        @media (max-width: 575px) {
            left: 0;
        }
    }

    .owl-dots {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding-bottom: 20px;

        .owl-dot {

            span {
                background: transparent !important;
                border: 1px solid $color-white;
                margin: 4px !important;
            }

            &.active, &:hover {
                span {
                    background: $color-white !important;
                }
            }
        }
    }
}

.owl-theme {
    .disabled {
        display: none;
    }

    .owl-nav.disabled {
        display: block !important;
    }

}

.owl-theme.owlHomeSecond {
    margin-bottom: 60px;

    @media (max-width: 767px) {
        margin-bottom: 45px;
    }

    .owl-nav {
        margin: 0;
    }

    .owl-prev, .owl-next {
        width: 66px;
        height: 66px;
        margin: 0 !important;
        display: block;
        background-color: #f0dacd !important;
        border-radius: 100% !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 1400px) {
            width: 40px;
            height: 40px;
        }
    }

    .owl-prev {
        left: calc(-66px - 28px);

        @media (max-width: 1400px) {
            left: -20px;
        }

        @media (max-width: 575px) {
            left: -15px;
        }
    }

    .owl-next {
        right: calc(-66px - 28px);

        @media (max-width: 1400px) {
            right: -20px;
        }

        @media (max-width: 575px) {
            right: -15px;
        }
    }

    .arrowLeft {
        width: 2px;
        height: 20px;
        position: absolute;
        background: $color-white;
        top: 50%;
        transform: translateY(-50%);
        left: 28px;

        @media (max-width: 1400px) {
            left: 16px;
        }

        .line {
            left: -8px;
        }
    }
    .arrowLeft:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-right: 10px solid #f0dacd;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
    .arrowLeft:before {
        content: "";
        position: absolute;
        left: -10px;
        bottom: 0;
        width: 0;
        height: 0;
        border-right: 10px solid $color-white;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    .arrowRight {
        width: 2px;
        height: 20px;
        position: absolute;
        background: $color-white;
        top: 50%;
        transform: translateY(-50%);
        right: 28px;

        @media (max-width: 1400px) {
            right: 16px;
        }

        .line {
            right: -8px;
        }
    }
    .arrowRight:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid #f0dacd;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
    .arrowRight:before {
        content: "";
        position: absolute;
        right: -10px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid $color-white;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    .line {
        display: block;
        height: 2px;
        width: 26px;
        background-color: $color-white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
}

.owlServices {
    @media (max-width: 991px) {
        margin-bottom: 0;
    }

    .left, .right {
        position: absolute;
        height: 100%;
        width: calc(100% / 5);
        background: rgba(255, 255, 255, 0.8);
        border: none;
        top: 0;

        @media (max-width: 1199px) {
            width: calc(100% / 4);
        }

        @media (max-width: 991px) {
            width: calc(100% / 3);
        }

        @media (max-width: 767px) {
            width: 40px;
            background: transparent;
        }

        .owl-prev, .owl-next {
            left: 50%;
            top: 39%;
            height: auto;
            width: auto;
            transform: translateX(-50%);
            background: transparent !important;

            .arrowLeft, .arrowRight {
                width: 26px;
                height: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                background: transparent;

            }

            .arrowLeft {

                .line {
                    left: 0;
                }

                &:after, &:before {
                    border: none !important;
                    width: 13px;
                    height: 2px;
                    left: -4px;
                    background-color: $color-black;
                }

                &:after {
                    top: 13px;
                    transform: rotate(45deg);
                }
                &:before {
                    bottom: 13px;
                    transform: rotate(-45deg);
                }
            }

            .arrowRight {
                .line {
                    right: 0;
                }

                &:after, &:before {
                    border: none !important;
                    width: 13px;
                    height: 2px;
                    right: -4px;
                    left: auto;
                    background-color: $color-black;
                }

                &:after {
                    bottom: 13px;
                    transform: rotate(45deg);
                }
                &:before {
                    top: 13px;
                    transform: rotate(-45deg);
                }
            }

            .line {
                background-color: $color-black;
            }
        }
    }

    .left {
        left: 0;

        .owl-prev {

        }
    }

    .right {
        right: 0;

        .owl-next {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.owlInspirations {
    margin-bottom: 0 !important;

    .left, .right {
        padding: 0;
        width: calc((100% - ((100% - 60px) / 2) - 119px) / 2);

        @media (max-width: 1199px) {
            width: calc((100% - ((100% - 20px) / 2) - 40px) / 2);
        }
    }
}