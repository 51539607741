@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.loginModal {
    .container {
        min-height: 100%;
    }

    .modalContent {

        .container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: left;

            @media (max-width: 767px) {
                position: relative;
            }
        }

        .leftWrapp {
            width: 60%;
            background: url("/img/bgLogin.jpg") left bottom no-repeat;
            background-size: cover;

            @media (max-width: 1200px) {
                width: 50%;
            }

            @media (max-width: 767px) {
                display: none;
            }

            .contentWrapp {
                padding-left: 30px;
                padding-right: 30px;
                width: 450px;
                margin: auto;
                max-width: 100%;

                .h1 {
                    font-size: 4.4rem;
                    line-height: 1.2em;
                    font-family: $font-secondary;
                    margin-bottom: 10px;
                }

                .p {
                    font-size: 2rem;
                    line-height: 1.2em;
                }

            }
        }

        .leftWrapp, .rightWrapp {
            float: left;
            min-height: 100%;

            @media (max-width: 767px) {
                width: 100% !important;
                overflow: auto !important;
            }

            .content {
                padding-top: 25vh;
                padding-bottom: 50px;

                @media (max-width: 767px) {
                    padding: 50px 30px !important;
                    width: 100% !important;
                }
            }
        }

        .rightWrapp {
            width: 40%;
            padding: 0 30px;
            overflow-y: scroll;
            background-color: #ffffff;

            @media (max-width: 767px) {
                padding: 0;
            }

            @media (max-width: 1200px) {
                width: 50%;
            }

            .content {
                position: relative;
                width: 415px;
                max-width: 100%;
                margin: auto;

                @media (max-width: 767px) {
                    position: static;
                }

                .btnClose {
                    position: absolute;
                    right: 0;
                    top: 10vh;
                    cursor: pointer;

                    @media (max-width: 767px) {
                        right: 20px;
                        top: 5px;
                        padding: 10px;
                    }
                }
            }

            .h1 {
                font-size: 3rem;
                font-weight: 600;
                color: #636363;
                text-align: center;
                margin-bottom: 50px;
                display: flex;


                @media (max-width: 767px) {
                    color: #ffffff;
                    line-height: 1em;
                    background: #e1e1e1;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    margin-bottom: 45px;
                }

                .active {
                    color: #dd817f;

                    @media (max-width: 767px) {
                        color: #ffffff;
                        background: #dd817f;
                    }
                }

                a {
                    display: block;
                    color: #636363;
                    width: 50%;

                    &:hover {
                        text-decoration: none;
                    }

                    @media (max-width: 767px) {
                        padding: 20px 0 !important;
                        text-align: center !important;

                        &:after {
                            display: none !important;
                        }
                    }

                    &:first-child {
                        padding-right: 23px;
                        position: relative;
                        text-align: right;

                        @media (max-width: 991px) {
                            padding-right: 10px;
                        }


                        &:after {
                            content: '';
                            width: 1px;
                            height: 32px;
                            display: block;
                            background-color: #7e7e7e;
                            position: absolute;
                            right: 0;
                            top: 4px;
                        }
                    }

                    &:nth-child(2) {
                        padding-left: 20px;
                        text-align: left;


                        @media (max-width: 991px) {
                            padding-left: 9px;
                        }
                    }
                }
            }
        }
    }

    .contentSwitch {

        .translateWrapp {
            overflow: hidden;

            .translateBody {
                width: 200%;
                @include clearfix();
                transition: transform .3s;

                .contentTranslate {
                    width: 50%;
                    float: left;
                }
            }
        }

        &.left {
            .translateWrapp {
                .translateBody {
                    transform: translateX(-50%);
                }
            }
        }
    }
}

:global {
    .modal-dialog {
        opacity: 0;
        transition: opacity 0.1s linear 0.5s !important;

        &.active {
            opacity: 1;
            transition: opacity 0.1s linear 0.5s !important;
        }
    }
}

.more {
    font-size: 1.4rem;
    color: #7e7e7e;

    a {
        font-weight: 500;
        color: #b26866;

        &:hover {
            color: #b26866;
            text-decoration: none;
        }
    }
}