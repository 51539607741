@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.loginMenu {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 33%;
    background-color: rgb(91, 88, 86);
    z-index: 100;
    transform: translateX(-100%);
    transition: .4s transform;
    padding: 150px 35px 75px;

    &.headerDark {
        background-color: $color-bg-pink;
    }

    &.headerLight {
        background-color: $color-bg-pink;

        nav {
            li {
                span, a {
                    color: $color-black;
                }
            }
        }
    }

    @media (max-width: 767px) {
        width: 100%;
    }

    &.active {
        transform: translateX(0);
    }

    nav {
        li {
            margin-bottom: 10px;
            text-align: right;

            @media (max-width: 767px) {
                text-align: center;
            }

            span, a {
                color: $color-white;
                font-size: 2rem;
                text-transform: uppercase;
                cursor: pointer;
                font-weight: 500;
            }
        }
    }
}