button, button:focus {
    outline: none;
}

.btn {
    border: none;
    font-size: 1.8rem;
    border-radius: 0;
    padding: 16.5px 20px;
    text-transform: uppercase;
    max-width: 100%;
    white-space: pre-wrap;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:hover, &:focus {
        outline: none;
        box-shadow: none;
    }

    &.btn-sm {
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.2em;
        padding: 17px 30px;
    }

    &.btn-xs {
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.2em;
        padding: 10px 30px;
    }

    @media (max-width: 767px) {
        font-size: 1.4rem;
        padding: 9.5px 10px;
    }

    &:after {
        content: '';
        position: absolute;
        background-color: #b26866;
        width: 100%;
        height: 100%;
        left: 0;
        top: calc(100% - 2px);
        transition: top .2s;
        z-index: -1;
    }

    &:hover:after {
        top: 0;
    }

    &.radius {
        border-radius: 3px;
    }

    &.text-transform-initial {
        text-transform: initial;
    }

    &.no-after:after {
        display: none;
    }

    &.btn-primary {
        color: $color-white;
        background-color: $color-bg-pink;

        &:after {
            background-color: darken($color-bg-pink, 10%);
        }
    }

    &.btn-dark-pink {
        color: #ffffff;
        background-color: #dd817f;

        &:after {
            background-color: darken(#dd817f, 10%);
        }
    }

    &.btn-turquise {
        color: #ffffff;
        position: relative;
        overflow: hidden;
        background-color: #1cb3ba;
        z-index: 1;

        &:after {
            background-color: #1c8b92;
        }
    }
    &.btn-green {
        color: #ffffff;
        position: relative;
        overflow: hidden;
        background-color: #28bc19;
        z-index: 1;

        &:after {
            background-color: #4f674d;
        }
    }
    &.btn-white {
        background-color: #fff;
        border: 1px solid #d6d6d6;
        color: $color-black;

        &:after {
            display: none;
        }
    }

    &.btn-more {
        font-size: 1.4rem;
        padding: 16.5px 80px;
        color: #b6a597;
        background-color: #fafafa;
        position: relative;

        &:after {
            background-color: #F5F5F5;
        }

        &.btn-white {
            background-color: #fff;
        }

        .icoRight {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);

            svg {
                width: 10px;
                height: 10px;
            }
        }
    }

    &.btn-sm {
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.2em;
        padding: 16px 30px;
    }

    &.btn-xs {
        font-size: 1.1rem;
        font-weight: 700;
        letter-spacing: 0.1em;
        padding: 12px 23px;
    }
    &.btn-dark-grey {
        background-color: #d8d8d8;
        color: #fff;

        &:after {
            background-color: darken(#d8d8d8, 10%);
        }
    }

    &.btn-red {
        background-color: #dd817f;
        color: #fff;

        &:after {
            background-color: darken(#dd817f, 10%);
        }
    }

    &.btn-white-green {
        border: 1px solid #d6d6d6;
        background-color: transparent;
        color: #40c036;
        font-weight: 600;

        &:after {
            background-color: darken(#ffffff, 10%);
        }

    }

    &.btn-green-white {
        border: 1px solid #40c036;
        color: #ffffff;
        background-color: #40c036;

        &:after {
            background-color: darken(#40c036, 10%);
        }
    }

    &.btnIco {
        border: 1px solid transparent;
        position: relative;
        color: #000000;
        font-weight: 600;
        text-transform: none;
        cursor: pointer;
        padding: 14px 30px;
        background-color: transparent;

        .ico {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            height: 23px;
        }

        &:after {
            display: none;
        }
    }
}