.modal.modal-active {
    display: block
}

.modal.modalXl {
    height: 100%;

    .modal-dialog {
        height: 100%;
        margin: 0;
        max-width: 100%;
        width: 100%;

        .modal-content {
            height: 100%;
        }
    }
}

.btnClose {
    background: transparent;
    border: none;
    cursor: pointer;
}