@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.footer {
    background-color: $color-bg-pink;
    color: $color-white;
    overflow: hidden;

    @media (max-width: 991px) {
        text-align: center;
    }

    .footerTop {
        padding: 95px 0;

        @media (max-width: 1199px) {
            padding: 45px 0;
        }
    }

    &.footerDark {
        background-color: #5b5658;

        .footerBottom {
            background-color: #4B4648;
        }
    }


    &.footerGuest {
        padding: 75px 0;
        background-color: #ffffff;
        text-align: center;

        .linkHome {
            color: $color-black;
            font-size: 1.1rem;
            letter-spacing: 0.2em;
            text-transform: uppercase;
        }
    }

    .section {
        .h1 {
            color: $color-white;

            @media (max-width: 767px) {
                margin-bottom: 0;
            }

            &:before, &:after {
                background-color: $color-white;
            }
        }

        .icoRight svg polygon {
            fill: $color-white;
        }

        .footerMenu {
            padding: 50px 0 0;
            list-style: none;
            letter-spacing: 0.2em;

            .header {
                font-size: 1.6rem;
                margin-bottom: 15px;
            }

            .item {
                margin-bottom: 15px;
                font-size: 11px;
                font-weight: 300;

                .city{
                    font-weight: 600;
                }
            }
        }
    }

    .footerBottom {
        padding: 130px 0 60px;
        background-color: #dd817f;

        @media (max-width: 1199px) {
            padding: 45px 0;
        }

        .socialWrapp {
            margin-left: -20px;
            margin-bottom: 80px;

            @media (max-width: 991px) {
                margin-left: 0;
                margin-bottom: 45px;
            }

            .socialIco {
                padding: 0 20px;

                svg path {
                    fill: #ffffff;
                }
            }
        }

        .linksWrapp {
            @include clearfix();

            .linksList, .homeLink {
                a {
                    color: $color-white;
                    font-size: 1.6rem;
                    letter-spacing: 0.025em;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .pagesLinks {
                float: right;
                margin-right: -30px;
                @include clearfix();

                @media (max-width: 991px) {
                    margin-bottom: 25px;
                    margin-left: -30px;
                    margin-right: -30px;
                    float: none;
                    display: inline-block;
                }

                li {
                    display: block;
                    float: left;

                    &:not(:first-child) {
                        border-left: 1px solid #ffffff;
                    }

                    @media (max-width: 500px) {
                        width: 100%;
                        border: none !important;
                    }

                    a {
                        display: inline-block;
                        padding: 2px 30px;

                        @media (max-width: 767px) {
                            padding: 2px 10px;
                        }
                    }
                }
            }
        }
    }

    &.footerLight {
        background-color: #ffffff;

        .footerBottom {
            background-color: #f8f8f8;

            .socialWrapp {
                .socialIco {
                    svg path {
                        fill: #000000;
                    }
                }
            }

            .linksWrapp {
                .linksList, .homeLink {
                    a {
                        color: $color-black;
                    }
                }
            }

            .pagesLinks {
                li {
                    &:not(:first-child) {
                        border-left: 1px solid #000000;
                    }
                }
            }
        }
        .section {
            .h1 {
                color: $color-black;

                &:before, &:after {
                    background-color: $color-black;
                }
            }

            .icoRight svg polygon {
                fill: $color-black;
            }

            .footerMenu {

                .header {
                    color: #b26866;
                }

                .item {
                    color: $color-black;
                }
            }
        }
    }
}