@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.header {
    padding: 80px 0 45px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 111;
    background-color: $color-bg-pink;
    transition: background 0.3s cubic-bezier(.165, .84, .44, 1), padding 0.3s cubic-bezier(.165, .84, .44, 1);
    will-change: padding, background;

    
    &.headerDark {
        background-color: #5b5658;

        & + .headerHelper {
            background-color: #5b5658;
        }
    }

    &.headerLight {
        background-color: #ffffff;

        & + .headerHelper {
            background-color: #ffffff;
        }

        .logo {
            a {
                color: $color-black;
            }
        }

        .navbar {
            .navbarNav {
                .navItem {
                    a, span {
                        color: $color-black;

                        &:before, &:after {
                            background-color: $color-black;
                        }
                    }
                }
            }
        }
        .hamburgerWrapp {
            span {
                background-color: $color-black;
            }
        }
        .padlockWrapp {

            svg path {
                fill: $color-black;
            }
        }
    }

    @media (max-width: 991px) {
        padding: 50px 0;
    }

    @media (max-width: 767px) {
        padding: 20px 0;
    }

    .logo {
        @media (max-width: 1199px) {
            text-align: center;
        }

        @media (max-width: 991px) {
            text-align: center;
        }

        a {
            font-family: $font-secondary;
            color: $color-white;
            font-size: 3rem;
            line-height: 1em;
            font-weight: 400;
            text-transform: uppercase;
            transform: font-size 0.3s;

            &:hover {
                text-decoration: none;
            }

            @media (max-width: 767px) {
                font-size: 2.5rem;
            }
        }
    }

    & + .headerHelper {
        width: 100%;
        top: 0;
        left: 0;
        z-index: 50;
        height: 157px;
        background: $color-bg-pink;

        @media (max-width: 991px) {
            height: 132px;
        }

        @media (max-width: 767px) {
            height: 68px;
        }

    }

    &.isSticky {
        padding: 25px 0;

        @media (max-width: 767px) {
            padding: 15px 0;

            .logo a {
                font-size: 2rem;
            }
        }
    }

    .navbar {
        text-align: center;

        .navbarNav {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;

            .navItem {
                display: block;
                float: left;


                a, span {
                    font-size: 1.4rem;
                    font-weight: 500;
                    color: $color-white;
                    display: inline-block;
                    text-transform: uppercase;
                    padding: 10px 15px 5px;
                    position: relative;
                    overflow: hidden;

                    &:before, &:after {
                        content: '';
                        display: block;
                        height: 1px;
                        position: absolute;
                        background-color: $color-white;
                        transition: transform .3s;
                    }

                    &:before {
                        width: calc(100% - 18px);
                        left: 0;
                        bottom: 5px;
                        transform: translateX(-110%);
                    }

                    &:after {
                        width: calc(100% - 38px);
                        bottom: 0;
                        right: 0;
                        transform: translateX(110%);
                    }

                    &.active, &:hover {
                        text-decoration: none;

                        &:before {
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        &:after {
                            left: 50%;
                            transform: translateX(-50%);
                        }

                    }
                }

                &.dropdown {
                    position: relative;

                    .dropdownMenu {
                        display: none;
                        position: absolute;
                        right: 0;
                        overflow: hidden;

                        .navbarNav {
                            background-color: #ffffff;
                            margin: 0 5px 5px 5px;
                            box-shadow: 0px 1px 5px;


                            .navItem {
                                float: none;
                                width: 100%;
                                text-align: right;

                                a, span {
                                    color: #000000;
                                    white-space: nowrap;

                                    &:before, &:after {
                                        background-color: #000000;
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        .dropdownMenu {
                            display: block;
                        }
                    }
                }
            }
        }

        &.navbarReg {
            display: block;
            @include clearfix();

            .navbarNav {
                display: block;
                @include clearfix();
                float: right;
                margin-right: -15px;

                .navItem {
                    display: block;
                    float: left;
                }
            }
        }
    }

    .headerContent {
        position: relative;
        z-index: 102;
    }


    .hamburgerWrapp {
        margin-top: 5px;
        float: right;
        width: 50px;
        height: 25px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;

        @media (max-width: 991px) {
            width: 26px;
            height: 15px;
            margin-top: 8px;
        }

        @media (max-width: 767px) {
            width: 26px;
            height: 15px;
            margin-top: 4px;
        }

        span {
            width: 100%;
            height: 3px;
            display: block;
            position: absolute;
            background-color: $color-white;
            transition: all .3s;

            @media (max-width: 991px) {
                height: 1px;
            }

            &:first-child {
                top: 0;
                transform: rotate(0) translateY(0);
            }


            &:nth-child(2) {
                top: 11px;
                transition: opacity .2s;

                @media (max-width: 991px) {
                    top: 7px;
                }
            }

            &:last-child {
                bottom: 0;
                transform: rotate(0) translateY(0);
            }
        }

        &.active {
            span {
                &:first-child {
                    top: 50%;
                    transform: rotate(45deg) translateY(-50%);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:last-child {
                    bottom: 50%;
                    transform: rotate(-45deg) translateY(50%);
                }
            }
        }
    }

    .padlockWrapp {
        position: absolute;
        top: 0;
        cursor: pointer;
        transition: opacity .3s;

        svg {
            height: 33px;
            width: 26px;
            transition: opacity .3s;

            @media (max-width: 991px) {
                height: 22px;
                width: 19px;
                margin-top: 7px;
            }
            @media (max-width: 767px) {
                top: 5px;
            }
        }

        .hamburgerWrapp {
            right: auto;
            left: 0;
            opacity: 0;
            transform: rotate(0);
            transition: all .3s;
        }

        &.active {
            svg {
                opacity: 0;
            }

            .hamburgerWrapp {
                opacity: 1;
                transform: rotate(360deg);
            }
        }
    }
}

