@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.organizerPage {
    .section {
        .header {
            font-size: 3rem;
            line-height: 1.2em;
            font-weight: 600;
            color: #000000;
            text-align: center;
            margin-bottom: 10px;

            @media (max-width: 991px) {
                font-size: 2.4rem;
            }
        }

        .headerDescription {
            font-weight: 300;
            font-size: 2rem;
            line-height: 1.2em;
            text-align: center;

            @media (max-width: 991px) {
                font-size: 1.8rem;
            }
        }

        .pink {
            color: #dd817f;
        }

        br {
            @media (max-width: 767px) {
                display: none;
            }
        }

        .white {
            color: #ffffff;
        }

        .moreDetailsWrapp {
            margin-top: 20px;

            .moreDetails {
                font-size: 1.2rem;
                color: #000000;

                .strong {
                    color: #b26866;
                    cursor: pointer;
                }
            }
        }

        &.sectionTop {
            padding: 100px 0 130px;
            background: url("/img/organizer/bgMulti.jpg") no-repeat center center;
            background-size: cover;

            @media (max-width: 1199px) {
                padding: 80px 0;
            }

            @media (max-width: 991px) {
                padding: 45px 0;
            }

            .header {
                font-weight: 400;
                margin-bottom: 20px;
                text-align: left;

                @media (max-width: 991px) {
                    font-size: 2.4rem;
                }
            }
        }

    }
}