form {
    .Select-control {
        border-radius: 0;
        border: none;
        box-shadow: none !important;

        > *:last-child {
            padding: 0 20px;
        }

        .Select-clear {
            display: inline-block;
            font-size: 49px;
            line-height: 1;
            font-weight: 100;
            color: #dddddd;
        }
    }

    .partnerInput {
        font-size: 1.2rem;

        &.Select {
            width: 320px;
            max-width: 100%;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        .Select-control, .form-control {
            width: 320px;
            border: 1px solid #f2f2f2;
            font-size: 1.2rem;
            max-width: 100%;
            background-color: #f8f8f8;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        textarea.form-control {
            width: 100%;
            max-width: none;
            font-size: 1.4rem;
        }

        .form-control {
            padding: 5.5px 15px;
            @media (max-width: 400px) {
                padding-left: 5px;
                padding-right: 5px;
            }
            &.hasValue {
                color: #7e7e7e;
            }
        }

        .Select-arrow {
            border: none;
            top: -7px !important;
            right: 8px;
        }

        .Select-arrow:after, .Select-arrow:before {
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        .Select-arrow:after {
            border-color: rgba(241, 241, 241, 0);
            border-top-color: #f8f8f8;
            border-width: 8px;
            margin-left: -8px;
        }
        .Select-arrow:before {
            border-color: rgba(221, 221, 221, 0);
            border-top-color: #DDDDDD;
            border-width: 10px;
            margin-left: -10px;
        }

        .Select-input {
            width: 100%;

            input{
                opacity: 0;
            }
        }
    }

    .rw-calendar-popup {
        width: calc(100% + 12px) !important;
    }

    // inputy w partner offers
    .offerPartnerInput{
        font-size: 1.1rem;

        .form-control {
            padding: 5.5px 15px;

            &.hasValue {
                color: #7e7e7e;
            }
        }
        .Select-control, .form-control {
            background-color: #e1e1e1 !important;
            border: 1px solid #e9e9e9;
            font-size: 1.2rem;
            height:48px;
        }
        .Select-arrow {
            border: none;
            top: -7px !important;
            right: 8px;
        }

        .Select-arrow:after, .Select-arrow:before {
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        .Select-arrow:after {
            border-color: rgba(241, 241, 241, 0);
            border-top-color: #e1e1e1;
            border-width: 8px;
            margin-left: -8px;
        }

        .Select-arrow:before {
            border-color: rgba(221, 221, 221, 0);
            border-top-color: #434343;
            border-width: 10px;
            margin-left: -10px;
        }

        .Select-placeholder, .Select-control .Select-value {
            line-height: 48px;
        }
        .Select-control .Select-input:focus {
            background-color: #e1e1e1 !important;
            border:none;
            outline:none;
        }
        .Select-placeholder, .Select-control .Select-value {
            line-height:48px;
            color:#434343;
        }

    }

    .inlineFieldWrapp {
        display: flex;

        @media (max-width: 767px) {
            display: block;
        }

        .inlineLabel {
            width: 260px;
            font-size: 1.2rem;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0;
            padding: 9px 0;

            label {
                margin: 0;
                font-size: 1.2rem;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &.form-group {
            margin-bottom: 18px;
        }
    }
}

.form-control {
    height: auto;

    &:focus {
        box-shadow: none;
    }
}

.error-wrapper {
    background-color: $color-light-pink;
    color: white;
    font-size:10px;
    padding: 2px 5px;
    letter-spacing: 0.1em;
    font-family: $font-primary;
}