@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ffffff;
    z-index: 50000;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    &:not(.active) {
        opacity: 0;
        visibility: visible;
        pointer-events: none;
    }

    .logo {
        font-family: $font-secondary;
        color: #000000;
        font-size: 3rem;
        line-height: 1em;
        font-weight: 400;
        text-transform: uppercase;
        display: inline-block;
        animation: rotating 4s linear infinite;
    }

    @keyframes rotating {

        0% {
            transform: rotate(0deg);
        }

        60% {
            transform: rotate(720deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }
}

