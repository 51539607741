html{
    font-size:62.5%;
    line-height: 62.5%;
}

body {
    min-height: 100vh;
    background: #fefefe;
    font-family: $font-primary;
    overflow-x: hidden;
    transition: .4s transform;
}

@media (min-width: 1240px) {
    .container {
        max-width: 1200px;
    }
}

@media (min-width: 1640px) {
    .containerXl {
        max-width: 1400px;
    }
}