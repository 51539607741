@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.labelContainer {
    :global{
        .default{
            display: flex;
            position: relative;
            padding-left: 27px;
            margin-bottom: 0;
            cursor: pointer;
            color: #aaaaaa;
            font-size: 1.4rem;
            font-weight: 400;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            align-items: center;
            line-height: 2.3rem;
            margin-top: 2px;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }

            a {
                color: #b26866;
                font-weight: 500;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: white;
                border: 1px solid #e8e8e8;
                border-radius: 2px;

                &:after {
                    display: none;
                    position: absolute;
                    content: '';
                    left: 3px;
                    top: -2px;
                    width: 20px;
                    height: 20px;
                    color: white;
                    font-size: 3rem;
                    font-weight: 400;
                }
            }

            input:checked ~ .checkmark {
                background-color: rgba(240, 171, 179, 0.5);

                &:after {
                    display: block;
                }
            }

            &:hover input ~ .checkmark {
                background-color: rgba(240, 171, 179, 0.5);
            }
        }
    }

    :global{
        .checkboxOrganizer{
            height:37px;
            position: relative;
            margin:0;
            cursor: pointer;
            padding-left:55px;
            font-size:1.2rem;
            font-weight:bold;
            letter-spacing: 1px;
            color:#747474;
            display: flex;
            align-items: center;

            @media (max-width: 576px){
                padding-left:50px;
            }

            input[type="checkbox"]{
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }

            input[type="checkbox"]:checked + .checkmark{
                display: block;
            }

            &:before{
                content: '';
                width:37px;
                height:37px;
                border:1px solid #fbf2f2;
                border-radius:1px;
                background:#ffffff;
                position: absolute;
                top:0;
                left:0;
            }

            .checkmark{
                width:16px;
                height:12px;
                display: inline-block;
                position: absolute;
                top:50%;
                left:18px;
                transform: translate(-50%, -50%);
                display: none;

                svg{
                    width:16px;
                    height:12px;

                    path{
                        stroke:#dd817f;
                    }
                }
            }
        }

        .checkboxAddGuest{
            height: 22px;
            position: relative;
            margin:0;
            cursor: pointer;
            padding-left:40px;
            font-size:1.2rem;
            letter-spacing: 1px;
            color:#747474;
            display: flex;
            align-items: center;

            input[type="checkbox"]{
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }

            input[type="checkbox"]:checked + .checkmark{
                display: block;
            }

            &:before{
                
                content: '';
                width: 22px;
                height: 22px;
                border-radius: 3px;
                border: 1px solid #cccccc;
                background:#ffffff;
                position: absolute;
                top:0;
                left:0;
            }

            .checkmark{
                width: 12px;
                height: 15px;
                display: inline-block;
                position: absolute;
                top:50%;
                left:11px;
                transform: translate(-50%, -50%);
                display: none;

                svg{
                    width: 13px;
                    height: 10px;

                    path{
                        stroke:#dd817f;
                    }
                }
            }
        }

        .checkboxWithoutLabel{
            height:37px;
            position: relative;
            margin:0;
            cursor: pointer;
            font-size:1.2rem;
            font-weight:bold;
            color:#747474;
           display: inline-block;
            align-items: center;
            width: 40px;

            input[type="checkbox"]{
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }

            input[type="checkbox"]:checked + .checkmark{
                display: block;
            }

            &:before{
                content: '';
                width:37px;
                height:37px;
                border:2px solid #fbf2f2;
                border-radius:1px;
                background:#ffffff;
                position: absolute;
                top:0;
                left:0;
            }

            .checkmark{
                width:16px;
                height:12px;
                display: inline-block;
                position: absolute;
                top:50%;
                left:18px;
                transform: translate(-50%, -50%);
                display: none;

                svg{
                    width:16px;
                    height:12px;

                    path{
                        stroke:#dd817f;
                    }
                }
            }
        }

        .partner {
            position: relative;
            padding-left: 40px;
            font-size: 1.4rem;
            font-weight: 400;

            input[type="checkbox"] {
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }

            input[type="checkbox"]:checked + .checkmark {
                background-color: #19b5bc;
            }


            .checkmark {
                position: absolute;
                left: 0;
                top: 0;
                margin-top: 0;
                width: 20px;
                height: 20px;
                display: block;
                border: 1px solid #fbf2f2;
                border-radius: 1px;
                background-color: transparent;
                transition: background-color .3s;

                &.white {
                    background-color: #ffffff;
                }
            }
        }
    }
}