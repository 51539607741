@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.btn {
    margin: 40px 0 20px;
}