@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

:global{
    @import 'resources/variables.scss';
    @import 'components/_body.scss';

    .categorySliderSlick .slick-slide{
        background-color: #ffffff;
        background-image: linear-gradient(to top, #ffffff 0%, #e2e3e6 100%);
        opacity: 0.5;
    }

    .categorySliderSlick .slick-slide.slick-active {
        opacity: 1;
    }

    .categorySliderSlick .slick-prev{
        left: 7%;
        cursor: pointer;
        @media(max-width:1500px){
            left: 3%;
        }
    }

    .categorySliderSlick .slick-next{
        right: 7%;
        cursor: pointer;
        @media(max-width:1500px){
            right: 3%;
        }
    }
    
    @import 'components/_buttons.scss';
    @import 'components/_page.scss';
    @import 'components/_filters.scss';
    @import 'components/_form.scss';
    @import 'components/_owlHome.scss';
    @import 'components/_modal.scss';
}

