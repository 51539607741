@mixin clearfix() {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Playfair Display', sans-serif;

$color-pink: #b26866;
$color-light-pink: #dd817f;
$color-bg-pink: #edbdbc;
$color-beige: #f7f0ea;
$color-beige-light: #fdfaf9;
$color-brown: #b98978;
$color-bg: #f8f8f8;
$color-grey: #7e7e7e;
$color-white: white;
$color-black: black;
$color-dark-grey: #6c6c6c;
$color-light-grey: #979797;
$color-lightner-grey: #cccccc;
$color-borders: #e3e3e3;

.mobileMenu {
    position: fixed;
    top: 0;
    right: 0;
    min-height: 100vh;
    width: 33.333%;
    background-color: rgb(91, 88, 86);
    z-index: 100;
    transform: translateX(100%);
    transition: .4s transform;
    padding: 170px 35px 75px;

    &.headerDark {
        background-color: $color-bg-pink;
    }

    &.headerLight {
        background-color: $color-bg-pink;

        nav {
            li {
                a {
                    color: $color-black;

                    &.active {

                        &:before, &:after {
                            background-color: $color-black;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        padding: 150px 35px 75px;
    }

    @media (max-width: 767px) {
        width: 100%;
    }

    &.active {
        transform: translateX(0);
    }

    nav {
        li {
            margin-bottom: 10px;
            @media (max-width: 767px) {
                text-align: center;
            }

            a {
                color: $color-white;
                font-size: 2rem;
                text-transform: uppercase;
                position: relative;

                &:hover {
                    text-decoration: none;
                }

                &.active {

                    &:before, &:after {
                        content: '';
                        display: block;
                        height: 1px;
                        position: absolute;
                        background-color: $color-white;
                        transition: transform .3s;
                    }

                    &:before {
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                    }

                    &:after {
                        left: 50%;
                        transform: translateX(-50%);
                        width: calc(100% - 20px);
                    }

                }

            }
        }
    }
}